import Colors from '@root/brand/src/utils/colors';
import MylesColors from '@root/trymyles.com/src/utils/myles-colors.js';
import MylesLogo from '@root/trymyles.com/src/components/myles-logo';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Seo from '@root/trymyles.com/src/components/seo';
import normalizeStyles from '@root/joinroot.com/src/components/normalize.css';
import { StyleSheet } from '@root/core/src/utils/styles';
import { injectGlobal } from '@root/vendor/emotion';
import { light, regular, semiBold, semiBoldItalic } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default () => (
  <>
    <Seo
      author={'Root Insurance Co.'}
      description={'The Myles roadside assistance app lets you earn free roadside service, just for driving. With a few taps, you can request the service you need and receive driver updates, so you know when help is on the way. And you can get roadside service as soon as you download the app - no membership required.'}
      title={'Myles Privacy Policy'}
    />
    <div css={styles.container}>
      <section css={styles.sectionHeader}>
        <MylesLogo css={styles.logo} />
        <h1 css={styles.heading1}>Privacy Policy</h1>
        <h6 css={styles.heading6}>Last modified: August 16, 2019</h6>
      </section>
      <section css={styles.copy}>
        <h2 css={styles.paraHeader}>Introduction</h2>
        <p><span>Root, Inc. (&#34;</span><span css={styles.boldedText}>Company</span><span>&#34; or &#34;</span><span css={styles.boldedText}>We</span><span>&#34;) respect your privacy and are committed to protecting it through our compliance with this policy. This policy describes:</span></p>
        <ul>
          <li><p><span>The types of information we may collect or that you may provide when you download, install, register with, access, or use <span css={styles.boldedText}>Myles</span> (the &#34;</span><span css={styles.boldedText}>App</span><span>&#34;).</span></p></li>
          <li><p>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</p></li>
        </ul>
        <p>This policy applies only to information we collect in this App and, via email, text, and other electronic communications sent through or in connection with this App.</p>
        <p>This policy DOES NOT apply to information that:</p>
        <ul>
          <li><p>We collect offline or on any other Company apps or websites, including websites you may access through this App.</p></li>
          <li>
            <p>
              <span>You provide to or is collected by any third party (see </span>
              <a
                css={styles.referenceTo}
                href={'#thirdParty'}
                target={'_self'}
              >Third-Party Information Collection
              </a>
              <span>).</span>
            </p>
          </li>
        </ul>
        <p>Our websites and apps, and other third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.</p>
        <p><span>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, and/or using this App, you agree to this privacy policy. This policy may change from time to time (see </span>
          <a
            css={styles.referenceTo}
            href={'#changesToPrivacy'}
            target={'_self'}
          >Changes to Our Privacy Policy
          </a><span>). Your continued use of this App after we revise this policy means you accept those changes, so please check the policy periodically for updates.</span>
        </p>
        <h2 css={styles.paraHeader}>Children Under the Age of 16</h2>
        <p><span>The App is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at </span>
          <a
            css={styles.referenceTo}
            href={'mailto:trymyles@joinroot.com'}
          >trymyles@joinroot.com.
          </a>
        </p>
        <h2 css={styles.paraHeader}>Information We Collect and How We Collect It</h2>
        <p>We collect information from and about users of our App:</p>
        <ul>
          <li><p>Directly from you when you provide it to us.</p></li>
          <li><p>Automatically when you use the App.</p></li>
        </ul>
        <h2 css={styles.paraHeader2}>Information You Provide to Us</h2>
        <p>When you download, register with, or use this App, we may ask you provide information:</p>
        <ul>
          <li><p><span>By which you may be personally identified, such as name, postal address, email address, telephone number, social security number, or any other information the app collects that is defined as personal or personally identifiable information under an applicable law (&#34;</span><span css={styles.boldedText}>Personal Information</span><span>&#34;).</span></p></li>
          <li><p>That is about you but individually does not identify you.</p></li>
        </ul>
        <p>This information includes:</p>
        <ul>
          <li><p>Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App and/or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with the App.</p></li>
          <li><p>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</p></li>
          <li><p>Your responses to surveys that we might ask you to complete for research purposes.</p></li>
          <li><p>Details of transactions you carry out through the App and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the App.</p></li>
          <li><p>Your search queries on the App.</p></li>
        </ul>
        <p css={styles.para}><span>You may also provide information for publication or display (&#34;</span><span css={styles.boldedText}>Posted</span><span>&#34;) on public areas of websites you access through the App (collectively, &#34;</span><span css={styles.boldedText}>User Contributions</span>
          <span>&#34;). Your User Contributions are Posted and transmitted to others at your own risk. Although, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
          </span>
        </p>
        <h2 css={styles.paraHeader2}>Automatic Information Collection and Tracking</h2>
        <p>When you download, access, and use the App, it may use technology to automatically collect:</p>
        <ul>
          <li><p><span css={styles.boldedText}>Usage and Location Information. </span><span>This App collects real-time information about the location of your device. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including certain telemetric data from your mobile device such as accelerometer, GPS and gyroscope data, as well as traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App. We will continue to monitor your device activity until you otherwise change your permissions.</span></p></li>
          <li><p><span css={styles.boldedText}>Device Information. </span><span>We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</span></p></li>
          <li><p><span css={styles.boldedText}>Stored Information and Files. </span><span>The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.</span></p></li>
        </ul>
        <p css={styles.para}><span>If you do not want us to collect this information do not download the App or delete it from your device. For more information, see </span><span>Choices About How We Use And Disclose Your Information</span><span>. Note, however, that opting out of the App’s collection of location information will cause its location-based features to be disabled.</span></p>
        <p css={styles.para}>We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking). </p>
        <h2 css={styles.paraHeader2}>Information Collection and Tracking Technologies</h2>
        <p css={styles.para}>The technologies we use for automatic information collection may include:</p>
        <ul>
          <li><p><span css={styles.boldedText}>Cookies (or mobile cookies). </span><span>A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.</span></p></li>
          <li><p><span css={styles.boldedText}>Web Beacons. </span><span>Pages of the App may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).</span></p></li>
        </ul>
        <a name={'thirdParty'}><h2 css={styles.paraHeader2}>Third-Party Information Collection</h2></a>
        <p>When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
        <ul>
          <li><p>Advertisers, ad networks, and ad servers.</p></li>
          <li><p>Analytics companies.</p></li>
          <li><p>Your mobile service provider.</p></li>
        </ul>
        <p css={styles.para}>These third parties may use tracking technologies to collect information about you when you use this app. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
        <p css={styles.para}><span>We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see </span>
          <a
            css={styles.referenceTo}
            href={'#choicesAbout'}
            target={'_self'}
          >Your Choices About Our Collection, Use, and Disclosure of Your Information.
          </a>
        </p>
        <h2 css={styles.paraHeader}>How We Use Your Information</h2>
        <p css={styles.para}>We use information that we collect about you or that you provide to us, including any personal information, to:</p>
        <ul>
          <li><p>Provide you with the App and its contents, and any other information, products or services that you request from us.</p></li>
          <li><p>Fulfill any other purpose for which you provide it.</p></li>
          <li><p>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</p></li>
          <li><p>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</p></li>
        </ul>
        <p>The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:</p>
        <ul>
          <li><p>Estimate our audience size and usage patterns.</p></li>
          <li><p>Store information about your preferences, allowing us to customize our App according to your individual interests.</p></li>
          <li><p>Speed up our services.</p></li>
          <li><p>Recognize you when you use the App.</p></li>
        </ul>
        <p>We use location information we collect to provide usage-based driving information.</p>
        <p>We may use the information we collect to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
        <h2 css={styles.paraHeader}>Disclosure of Your Information</h2>
        <p>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.</p>
        <p>In addition, we may disclose personal information that we collect or you provide:</p>
        <ul>
          <li><p>To our subsidiaries and affiliates.</p></li>
          <li><p>To contractors, service providers, and other third parties we use to support our business.</p></li>
          <li><p>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Root’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Root about our App users is among the assets transferred.</p></li>
          <li><p>To third parties to market their products or services to you if you have not opted out of these disclosures.</p></li>
          <li><p>To fulfill the purpose for which you provide it.</p></li>
          <li><p>For any other purpose disclosed by us when you provide the information.</p></li>
          <li><p>With your consent.</p></li>
          <li><p>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</p></li>
          <li><p>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Root, our customers or others.</p></li>
        </ul>
        <a name={'choicesAbout'}><h2 css={styles.paraHeader}>Your Choices About Our Collection, Use, and Disclosure of Your Information</h2></a>
        <p css={styles.para}>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.</p>
        <ul>
          <li><p><span css={styles.boldedText}>Tracking Technologies. </span><span>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly</span></p></li>
          <li><p><span css={styles.boldedText}>Location Information. </span> <span>You can choose whether or not to allow the App to collect and use real-time information about your device’s location through the device’s privacy settings. If you block the use of location information, some parts of the App may then be inaccessible or not function properly</span></p></li>
        </ul>
        <p><span>We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&#34;</span><span css={styles.boldedText}>NAI</span><span>&#34;) on the NAI’s website.</span></p>
        <h2 css={styles.paraHeader}>Data Security</h2>
        <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.</p>
        <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the App like message boards. The information you share in public areas may be viewed by any user of the App.</p>
        <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
        <a name={'changesToPrivacy'}><h2 css={styles.paraHeader}>Changes to Our Privacy Policy</h2></a>
        <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users’ personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated. The date the privacy policy was last revised is identified at the top of the page.</p>
        <h2 css={styles.paraHeader}>Contact Information</h2>
        <p><span>To ask questions or comment about this privacy policy and our privacy practices, contact us at: </span>
          <a
            css={styles.referenceTo}
            href={'mailto:trymyles@joinroot.com'}
          >trymyles@joinroot.com
          </a>
        </p>
      </section>
    </div>
  </>
);

const styles = StyleSheet.create({
  container: {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: Colors.white(),
    marginBottom: '5em',
  },
  sectionHeader: {
    textAlign: 'center',
    ...regular(),
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '3.75em 2em 0 2em',
    ...Responsive.lg({
      maxWidth: '720px',
    }),
    svg: {
      marginTop: '20px',
      fill: MylesColors.mylesGreen(),
      stroke: MylesColors.mylesGreen(),
      transition: 'all 250ms cubic-bezier(0.37, 0, 0.28, 1)',
      width: '30vw',
    },
  },
  heading1: {
    margin: '1em 0 0 0',
    fontSize: '3em',
    lineHeight: 1.25,
    color: Colors.gray60(),
    ...Responsive.lessThanSm({
      fontSize: '2em',
      lineHeight: 1.1,
    }),
  },
  heading6: {
    margin: '2px 0 0 0',
    fontSize: '1em',
    lineHeight: 1.5,
    color: Colors.gray50(),
    ...regular(),
  },
  boldedText: {
    ...semiBold(),
    color: MylesColors.mylesGreen(),
  },
  copy: {
    padding: '0.75em 2em 0 2em',
    ...light(),
    fontSize: '1.2em',
    lineHeight: 1.5,
    color: Colors.gray60(),
    marginRight: 'auto',
    marginLeft: 'auto',
    ...Responsive.lg({
      maxWidth: '720px',
    }),
    p: {
      ...light(),
      marginTop: '1em',
      marginBottom: 0,
      fontSize: '1em',
      lineHeight: 1.5,
      color: Colors.gray60(),
      textAlign: 'justify',
    },
    ul: {
      listStyleType: 'circle',
      margin: '0.5em 0 0 0',
      padding: '0 0 0 2em',
      color: Colors.gray40(),
    },
  },
  referenceTo: {
    ...regular(),
    ':link': {
      color: MylesColors.mylesGreen(),
      textDecoration: 'none',
    },
    ':active': {
      color: Colors.DEPRECATED_purple(),
      textDecoration: 'underline',
    },
    ':hover': {
      color: Colors.DEPRECATED_purple(),
      textDecoration: 'none',
    },
    ':visited': {
      color: Colors.DEPRECATED_green(),
      textDecoration: 'none',
    },
  },
  paraHeader: {
    fontSize: '1.2em',
    lineHeight: 1.5,
    textTransform: 'uppercase',
    color: Colors.gray60(),
    marginTop: '2em',
    marginBottom: 0,
  },
  paraHeader2: {
    fontSize: '1em',
    lineHeight: 1.5,
    color: Colors.gray60(),
    marginTop: '1em',
    marginBottom: '0.5em',
    ...semiBoldItalic(),
  },
});

injectGlobal(normalizeStyles);
